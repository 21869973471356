import revive_payload_client_4sVQNw7RlN from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_AUP22rrBAc from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import countries_760necx3aU from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/plugins/countries.ts";
import gtm_client_OzmBuHLRIb from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/plugins/gtm.client.ts";
import i18n_VfGcjrvSkj from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/plugins/i18n.ts";
import imgix_client_SQrx0rvwfW from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/plugins/imgix.client.ts";
import recaptcha_client_ElRG0N5AcO from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/plugins/recaptcha.client.ts";
import sentry_client_shVUlIjFLk from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/plugins/sentry.client.ts";
import vuetify_7h9QAQEssH from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/plugins/vuetify.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_AUP22rrBAc,
  countries_760necx3aU,
  gtm_client_OzmBuHLRIb,
  i18n_VfGcjrvSkj,
  imgix_client_SQrx0rvwfW,
  recaptcha_client_ElRG0N5AcO,
  sentry_client_shVUlIjFLk,
  vuetify_7h9QAQEssH
]