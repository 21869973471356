import { default as about_45uslKFVgF4LEBMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/about-us.vue?macro=true";
import { default as checkout0UbSCyIbo4Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/checkout.vue?macro=true";
import { default as _91id_93VvpFrxJeaVMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/[id].vue?macro=true";
import { default as indexdcQcOTsC3sMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/index.vue?macro=true";
import { default as contactxpmgSlJrU1Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/contact.vue?macro=true";
import { default as indexLYllS9xIjXMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/index.vue?macro=true";
import { default as my_45cartIPzdGywnQmMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/my-cart.vue?macro=true";
import { default as order_45confirmationstcPkdgRGeMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/order-confirmation.vue?macro=true";
import { default as productsJ5QIFENeO6Meta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/products.vue?macro=true";
import { default as _91id_931MviiWS6vgMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/textiles/[id].vue?macro=true";
import { default as indexk778pdZpSyMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/account-activation/index.vue?macro=true";
import { default as forgot_45passwordjLsO5u9UxxMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/forgot-password.vue?macro=true";
import { default as indexkU4D2DBwwtMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/index.vue?macro=true";
import { default as signin_45signup8DRRyoezjiMeta } from "/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/signin-signup.vue?macro=true";
export default [
  {
    name: "org_slug-about-us",
    path: "/:org_slug()/about-us",
    meta: about_45uslKFVgF4LEBMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/about-us.vue").then(m => m.default || m)
  },
  {
    name: "org_slug-checkout",
    path: "/:org_slug()/checkout",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/checkout.vue").then(m => m.default || m)
  },
  {
    name: "org_slug-collections-id",
    path: "/:org_slug()/collections/:id()",
    meta: _91id_93VvpFrxJeaVMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: "org_slug-collections",
    path: "/:org_slug()/collections",
    meta: indexdcQcOTsC3sMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "org_slug-contact",
    path: "/:org_slug()/contact",
    meta: contactxpmgSlJrU1Meta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/contact.vue").then(m => m.default || m)
  },
  {
    name: "org_slug",
    path: "/:org_slug()",
    meta: indexLYllS9xIjXMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "org_slug-my-cart",
    path: "/:org_slug()/my-cart",
    meta: my_45cartIPzdGywnQmMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/my-cart.vue").then(m => m.default || m)
  },
  {
    name: "org_slug-order-confirmation",
    path: "/:org_slug()/order-confirmation",
    meta: order_45confirmationstcPkdgRGeMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: "org_slug-products",
    path: "/:org_slug()/products",
    meta: productsJ5QIFENeO6Meta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/products.vue").then(m => m.default || m)
  },
  {
    name: "org_slug-textiles-id",
    path: "/:org_slug()/textiles/:id()",
    meta: _91id_931MviiWS6vgMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/[org_slug]/textiles/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-activation",
    path: "/account-activation",
    meta: indexk778pdZpSyMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/account-activation/index.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexkU4D2DBwwtMeta || {},
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "signin-signup",
    path: "/signin-signup",
    component: () => import("/home/runner/work/Frontend-TNGV/Frontend-TNGV/pages/signin-signup.vue").then(m => m.default || m)
  }
]