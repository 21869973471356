class CountriesFinder {
	/**
	 * Allows for searching the country a name and get the alpha2 associated or an alpha2 and get a name.
	 * @param {string} propertyFrom The name of the property from where it will search the array (examples: "name", "alpha2", etc.).
	 * @param {string} valueFrom The value of the property from where it will search the array (examples: "CA", "Canada", "US" etc.).
	 * @param {string} propertyTo The searched property that we want to extract.
	 */
	static async getCountryProperty(propertyFrom: string, valueFrom: string, propertyTo: string) {
		const countriesList = await this.getCountryList();
		const countryFound = countriesList.find(country => (<any>country)[propertyFrom] === valueFrom);
		if (countryFound === undefined) {
			throw new Error(
				`The country for the provided ${propertyFrom} "${valueFrom}" cannot be found in the country list.`
			);
		}
		const valueFound = (<any>countryFound)[propertyTo];
		return valueFound;
	}

	/**
	 * Attempts to find the corresponding alpha2 code of a country with its name.
	 * @param string name The name that we are trying to search.
	 * @returns The alpha2 code of the searched country that we've found or an error in case it is not found.
	 * @throws {Error} In case we are trying to search for a name when the name is not in the list
	 */
	static getNameToAlpha2(name: string) {
		return this.getCountryProperty("name_en", name, "alpha2");
	}

	/**
	 * Attempts to find the corresponding name of a country with its alpha2 code.
	 * @param string alpha The alpha2 code that we are trying to search.
	 * @returns The name of the searched country that we've found or an error in case it is not found.
	 * @throws {Error} In case we are trying to search for a name when the alpha2 is not in the list
	 */
	static getAlpha2ToName(alpha: string) {
		return this.getCountryProperty("alpha2", alpha, "name_en");
	}

	/**
	 * Gets and return the list of all of the countries.
	 * @returns The list of all of the countries
	 */
	static async getCountryList() {
		const { countriesList } = await import("@/plugins/data/countries_list");
		return countriesList;
	}
}

/**
 * @param {import("@nuxt/types").Context} param0
 * @param {import("@nuxt/types/app").Inject} inject
 */
export default defineNuxtPlugin(() => {
	return {
		provide: {
			countries: CountriesFinder,
		},
	};
});
