// create organization related store
import { defineStore, acceptHMRUpdate } from "pinia";
import type { OrgProfile } from "@/types/organizations";

// Store to keep the data of the organization's showroom currently being looked at.
// Ex: if you visit tngv.io/vrstex => organizationStore will populate with vrstex data.
// THIS IS NOT A STORE FOR THE USER'S ORGANIZATION, see user store for that.
export const useOrgStore = defineStore(
	"org",
	() => {
		const orgmApi = useOrgMApi();
		const organizationProfile = ref<OrgProfile | null>(null); // It's an org profile because the slug is stored on the profile.
		const platformId = ref<string>("");

		async function setOrganizationProfile(slugOrID: string) {
			// if the slug or id is different from the current one, fetch the new one
			if (
				slugOrID &&
				organizationProfile.value?.slug !== slugOrID &&
				organizationProfile.value?.organization_id !== slugOrID
			) {
				const { response, error } = await orgmApi.viewShowroomOrganizationProfile(slugOrID);
				if (error) throw error;
				organizationProfile.value = response?.data as OrgProfile;
				if (organizationProfile.value.organization?.service_subscriptions) {
					platformId.value =
						organizationProfile.value.organization?.service_subscriptions[0]?.subscription_platforms?.find(
							item => item.variant === "Showroom-Merchant"
						)?.platform_id as string;
				}
				return organizationProfile.value.organization_id;
			} else {
				return "";
			}
		}

		return {
			platformId,
			organizationProfile,
			setOrganizationProfile,
		};
	},
	{
		persist: {
			storage: persistedState.localStorage,
		},
	}
);

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useOrgStore, import.meta.hot));
}
