// create user related store
import { defineStore, acceptHMRUpdate } from "pinia";
import type { LoginForm } from "@/composables/useURM";
import type { User } from "@/types/user";
import type { OrgProfile } from "@/types/organizations";

// Store to keep data about the user logged in (user data, organization data ...)
export const useUserStore = defineStore(
	"User",
	() => {
		const { login, logout } = useURM();
		const { viewOrganization } = useOrgMApi();
		const { userAuth } = useURMApi();
		// data
		const user = ref<User>();
		const userOrganizationProfile = ref<OrgProfile>();
		// async|logic
		// function for user login
		async function userLogin(data: LoginForm, sso: boolean) {
			const userInfo = await login(data, sso);
			user.value = userInfo;
			if (user.value.organization_id) {
				const { response, error } = await viewOrganization(user.value.organization_id);
				if (error) throw error;
				const res: any = response?.data;
				userOrganizationProfile.value = res?.profile as OrgProfile;
			}
			return { user: user.value, organization: userOrganizationProfile.value };
		}

		// function for user logout
		function userLogout() {
			logout();
			user.value = undefined;
		}

		// fetch user info when there is token in cookie
		// user doesn't need to login again
		async function getUserInfo() {
			const { response, error } = await userAuth();
			user.value = (response?.data as any).user;
			const { response: orgRes, error: orgErr } = await viewOrganization(user.value?.organization_id as string);
			const res: any = orgRes?.data;
			userOrganizationProfile.value = res?.profile as OrgProfile;

			if (!error && !orgErr) {
				return "Success";
			} else {
				return Promise.reject(new Error("Failed"));
			}
		}

		return {
			user,
			userOrganizationProfile,
			userLogin,
			userLogout,
			getUserInfo,
		};
	},
	{
		persist: {
			storage: persistedState.localStorage,
		},
	}
);

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot));
}
