import { useUserStore } from "@/stores/modules/user";
import { useCartStore } from "@/stores/modules/cart";
import { useOrgStore } from "@/stores/modules/organization";

export default defineNuxtRouteMiddleware(async (to, _) => {
	// skip middleware on server
	if (import.meta.server) return;

	const userStore = useUserStore();
	const orgStore = useOrgStore();
	const { getCartData } = useCartStore();
	const { user } = storeToRefs(userStore);
	const { organizationProfile } = storeToRefs(orgStore);
	const jwtToken = useCookie<string>("jwt_token");

	if (jwtToken.value) {
		if (user.value && to.path === "/signin-signup") {
			await getCartData();
			return abortNavigation();
		} else if ((user.value && to.path !== "/signin-signup") || (user.value && to.path !== "/my-cart")) {
			await getCartData();
		} else if (user.value && to.path === "/my-cart") {
			return true;
		} else {
			try {
				await userStore.getUserInfo();
				await getCartData();
			} catch (error) {
				return navigateTo("/signin-signup");
			}
		}
	} else if (to.path === `/${organizationProfile.value?.slug}/my-cart`) {
		return navigateTo("/signin-signup");
	}
});
